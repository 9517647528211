<template>
  <div class="section">
    <div class="container">
      <h1 class="title is-2">New {{ heading }}</h1>

      <!--        <h1 class="subtitle is-5">{{ $route.params.bot }}</h1>
      <b-field class="file is-primary" :class="{ 'has-name': !!file }">
        <b-upload v-model="file" class="file-label">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">Click to upload</span>
          </span>
          <span class="file-name" v-if="file">
            {{ file.name }}
          </span>
        </b-upload>
      </b-field>-->

      LEGACY VIEW
      <!-- buefy switch for generateByAi prop -->
      <b-field label="">
        <b-switch v-model="generateWithAi" type="is-success" rounded @input="handleAiToggleActive()"
          >Generate with AI</b-switch
        >
      </b-field>

      <div v-if="generateWithAi && isText">
        <!-- AI text-->
        WIP
        <br />

        <b-button class="is-link" @click="clickGenerateText">{{ isEdit ? "Save" : "Generate" }}</b-button>
      </div>

      <div v-if="!generateWithAi && isText">
        <!-- Manual text   text-->

        <b-field label="Text" :type="validationErrors.text ? 'is-danger' : ''">
          <b-input v-model="text" type="textarea" placeholder="Write something..." expanded></b-input>
          <p class="help is-danger">{{ validationErrors.text }}</p>
        </b-field>

        <b-button class="is-link" @click="clickUploadText">{{ isEdit ? "Save" : "Post" }}</b-button>
      </div>

      <div v-if="generateWithAi && !isText">
        <!--  AI photo  -->
        <b-field label="Image prompt" type=" ">
          <b-input v-model="aiPhotoPrompt" type="textarea" placeholder="Drawing of a cat face..." expanded></b-input>
        </b-field>
        <b-button class="is-link" @click="clickGeneratePhoto">{{ isEdit ? "Save" : "Generate" }}</b-button>
      </div>
      <div v-if="!generateWithAi && !isText">
        <!--Manual photo  -->

        <!-- -->

        <!-- 
        <b-field class="file">
          <b-upload v-model="file" expanded>
            <a class="button is-primary is-fullwidth">
              <b-icon icon="upload"></b-icon>
              <span>{{ file.name || "Click to upload" }}</span>
            </a>
          </b-upload>
        </b-field>
        <b-field>-->
        <b-field class="file">
          <b-upload v-model="dropFiles" :multiple="true" drag-drop expanded>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"></b-icon>
                </p>
                <p>Drop your files here or click to upload</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div class="tags">
          <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
            {{ dataUrl(file) }}
            {{ file.name }}
            <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
          </span>
        </div>

        <hr />

        <b-field label="" v-show="dropFiles && dropFiles.length > 1">
          <b-switch v-model="uplaodAsSeparatePosts" type="is-success" rounded>Upload as separate posts</b-switch>
        </b-field>

        <b-field label="Caption (optional)" :type="validationErrors.text ? 'is-danger' : ''">
          <b-input v-model="text" type="textarea" placeholder="Write something..." expanded></b-input>
          <p class="help is-danger">{{ validationErrors.text }}</p>
        </b-field>

        <b-button class="is-link" @click="clickUploadPhotos" :loading="uploading">Upload photos </b-button>
      </div>
      <!-- 
      {{ generateWithAi }} ==generateWithAi

      <br />
      {{ isText }} == isText -->
      <!--  

   <hr />
        <b-field label="Upload image">
          <b-upload v-model="image" expanded :multiple="true">
            <span class="button is-primary">
              <b-icon icon="upload"></b-icon>
              <span>Click to upload</span>
            </span>
          </b-upload>
        </b-field>
        <div class="image-preview" v-if="imageUrl">
          <img :src="imageUrl" alt="Post image preview" />
        </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({ text: "", image: "" }),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.post.text,
      uplaodAsSeparatePosts: false,
      //image: this.post.image,
      validationErrors: {},
      generateWithAi: this.$route.query.ai == "true" ? true : false, //load bot default

      //  file: {},
      //image: "",
      dropFiles: [], //important upuadl
      uploading: false,

      //

      aiPhotoPrompt: "",
    };
  },
  computed: {
    imageUrl() {
      //createds dataurl to show in browser...
      return this.image ? URL.createObjectURL(this.image) : "";
    },
    botId() {
      return this.$route.params.bot;
    },
    /* */
    isText() {
      return this.$route.name === "newTextBotPost" || this.$route.name === "inboxNewConvoTask";
    },
    /*
    isTxt() {
      var n = this.$route.name;
      return n;
      if (this.$route.name && this.$route.name == "newTextBotPost") return true;
      else return false;
      return (this.$route.name = "newTextBotPost");
    },*/
    heading() {
      var a = this.isText;
      return a ? "Text Update 📝" : "Photo 📸";
    },
  },
  methods: {
    handleAiToggleActive() {
      var active = this.generateWithAi;
      //replace url query param "ai" with active
      var url = new URL(window.location.href);
      var searchParams = new URLSearchParams(url.search);
      searchParams.set("ai", active);
      url.search = searchParams.toString();
      var newUrl = url.toString();
      console.log(">>newUrl >> ", newUrl);
      //replace current state, dont push
      window.history.replaceState({}, "", newUrl);

      // this.generateWithAi = !this.generateWithAi;
    },
    dataUrl(i) {
      //createds dataurl to show in browser...
      return i ? URL.createObjectURL(i) : "";
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    clickGeneratePhoto() {
      var prompt = this.aiPhotoPrompt;
      var text = this.text;
      // generateBotImagePost;
      window.API.generateBotImagePost(this.botId, { prompt, text, engine: "bunny" }).then((res) => {
        console.log(">>generateBotImagePost res >> ", res);
        this.redirectSuccess(res.postId);
      });
    },
    clickGenerateText() {
      alert("tbd");
    },
    clickUploadText() {
      //manual txt post
      var prompt = this.aiPhotoPrompt;
      var post = { text: this.text };
      window.API.createBotPost(this.botId, post).then((res) => {
        console.log(">>createBotPost res >> ", res);
        this.redirectSuccess(res.postId);
      });
    },
    clickUploadPhotos() {
      this.validationErrors = {};

      if (!this.text.trim() && !this.dropFiles.length) {
        this.validationErrors.text = "Please enter some text.";
        return;
      }

      let formData = new FormData();
      //formData.append("singleImage", this.file);
      //TODO: extend support to more files
      console.log(this.dropFiles);
      //formData.append("dropFiles", this.dropFiles);
      for (let i = 0; i < this.dropFiles.length; i++) {
        //very imporant for Méultiple files
        formData.append("dropFiles", this.dropFiles[i]);
      }
      formData.append("text", this.text);
      formData.append("testttt", "📣📣s dsaf a📣📣s📣📣s");
      //uplaodAsSeparatePosts
      formData.append("uplaodAsSeparatePosts", this.uplaodAsSeparatePosts);
      console.log(">> formData >> ", formData);
      this.uploading = true;
      window.API.createBotPost(this.botId, formData).then((res) => {
        console.log(">>createBotPost res >> ", res);
        this.uploading = true;
        this.redirectSuccess(res.postId);
      });

      /*
      const postData = { text: this.text, images: this.image };
      console.log(postData, "posting....");
      if (this.isEdit) {
        var postId = this.$route.params.postId;
        window.API.editPost(this.botId, postId, postData); // replace with your actual API call for editing posts
      } else {
        window.API.createBotPost(this.botId, postData); // replace with your actual API call for posting new posts
      }
*/
      this.resetForm();
    },
    redirectSuccess(postId) {
      var url = window.location.href;
      //encoded url

      //remove host, keep path
      url = url.replace(window.location.origin, "");
      url = encodeURIComponent(url);

      this.$router.push({ name: "newBotPostFinish", params: { bot: this.botId, postId }, query: { re: url } });
    },
    resetForm() {
      this.text = "";
      this.image = "";
    },
  },
};
</script>
